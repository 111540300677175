<template>
  <div class="Site PageBox">
    <van-nav-bar
      fixed
      :border="false"
      :title="$t('common2[0]')"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <div class="ScrollBox">
      <van-button color="#0076FA" size="large" style="margin: 10px;width: 95%"  v-for="item in servicelist"
                  @click="$router.push(`/article/service/${item.id}`)"
      ><span>{{item.title}}</span>
      </van-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Service',
  components: {

  },
  props: {},
  data() {
    return {
      servicelist:[]
    }
  },
  computed: {

  },
  watch: {

  },
  created() {
    
  },
  mounted() {
    this.servicelist = this.InitData.serviceList;
  },
  activated() {

  },
  destroyed() {
    
  },
  methods: {
 
  }
}
</script>
<style scoped>
  .PageBox {
    color: #d7d7d7;
    background-color: #13171A;
  }

  .van-nav-bar {
    background-color: #191C23;
  }
  .van-nav-bar>>>.van-nav-bar__title{
    color:#fff;
    font-weight: bold;
  }
  .van-nav-bar>>>.van-nav-bar__arrow {
    color:#fff;
  }
  .ScrollBox{
    background-color: #13171A;
  }
</style>